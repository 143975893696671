const meistarklasesImages = [
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/14.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/13.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/12.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/11.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/10.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/9.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/8.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/7.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/6.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/5.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/4.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/3.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/2.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
  {
    sadala: "meistarklases",
    image: "/images/Meistarklases/1.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
];

export default meistarklasesImages;

import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store.js";
//import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/bootstrap.custom.css";
import "./assets/styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AdminRoute from "./components/AdminRoute.tsx";
import HomeScreen from "./screens/HomeScreen.tsx";
import ProductScreen from "./screens/ProductScreen.tsx";
import GleznasPecNumuriemScreen from "./screens/GleznasPecNumuriemScreen.tsx";
import KarikaturasScreen from "./screens/KarikaturasScreen.tsx";
import SienuGleznojumiScreen from "./screens/SienuGleznojumiScreen.tsx";
import GleznasScreen from "./screens/GleznasScreen.tsx";
import ParManiScreen from "./screens/ParManiScreen.tsx";
import KontaktiScreen from "./screens/KontaktiScreen.tsx";
import LoginScreen from "./screens/LoginScreen.jsx";
import GleznasDetailsScreen from "./screens/GleznasDetailsScreen.tsx";
import ReviewScreen from "./screens/ReviewScreen.tsx";
import GleznasPecNumuriemListScreen from "./screens/admin/GleznasPecNumuriemListScreen.tsx";
import GleznasPecNumuriemEditScreen from "./screens/admin/GleznasPecNumuriemEditScreen.tsx";
import KarikaturasListScreen from "./screens/admin/KarikaturasListScreen.tsx";
import KarikaturasEditScreen from "./screens/admin/KarikaturasEditScreen.tsx";
import SienasGleznojumiListScreen from "./screens/admin/SienasGleznojumiListScreen.tsx";
import SienasGleznojumiEditScreen from "./screens/admin/SienasGleznojumiEditScreen.tsx";
import MeistarklasesScreen from "./screens/MeistarklasesScreen.tsx";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomeScreen />} />
      <Route path="/product/:id" element={<ProductScreen />} />
      <Route
        path="/pakalpojumi/meistarklases"
        element={<MeistarklasesScreen />}
      />
      <Route
        path="/pakalpojumi/gleznPecNum"
        element={<GleznasPecNumuriemScreen />}
      />
      <Route path="/pakalpojumi/karikaturas" element={<KarikaturasScreen />} />
      <Route
        path="/pakalpojumi/sienuGleznojumi"
        element={<SienuGleznojumiScreen />}
      />
      <Route path="/pakalpojumi/gleznas" element={<GleznasScreen />} />
      <Route
        path="/pakalpojumi/gleznas/:id"
        element={<GleznasDetailsScreen />}
      />
      <Route path="/atsauksmes" element={<ReviewScreen />} />
      <Route path="/parMani" element={<ParManiScreen />} />
      <Route path="/kontakti" element={<KontaktiScreen />} />
      <Route path="/login" element={<LoginScreen />} />

      <Route path="" element={<AdminRoute />}>
        <Route
          path="/admin/gleznasPecNrList"
          element={<GleznasPecNumuriemListScreen />}
        />
      </Route>
      <Route path="" element={<AdminRoute />}>
        <Route
          path="/admin/gleznasPecNr/:id/edit"
          element={<GleznasPecNumuriemEditScreen />}
        />
      </Route>

      <Route path="" element={<AdminRoute />}>
        <Route
          path="/admin/karikaturasList"
          element={<KarikaturasListScreen />}
        />
      </Route>
      <Route path="" element={<AdminRoute />}>
        <Route
          path="/admin/karikaturas/:id/edit"
          element={<KarikaturasEditScreen />}
        />
      </Route>

      <Route path="" element={<AdminRoute />}>
        <Route
          path="/admin/sienuGleznojumiList"
          element={<SienasGleznojumiListScreen />}
        />
      </Route>
      <Route path="" element={<AdminRoute />}>
        <Route
          path="/admin/sienuGleznojumi/:id/edit"
          element={<SienasGleznojumiEditScreen />}
        />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const parManiImages = [
  {
    sadala: "parMani",
    image: "/images/ParMani/7.jpg",
    order: 7,
    width: 800,
    height: 1200,
  },
  {
    sadala: "parMani",
    image: "/images/ParMani/6.jpg",
    order: 6,
    width: 800,
    height: 1200,
  },
  {
    sadala: "parMani",
    image: "/images/ParMani/5.jpg",
    order: 5,
    width: 800,
    height: 1200,
  },
  {
    sadala: "parMani",
    image: "/images/ParMani/4.jpg",
    order: 4,
    width: 800,
    height: 1200,
  },
  {
    sadala: "parMani",
    image: "/images/ParMani/3.jpg",
    order: 3,
    width: 800,
    height: 1200,
  },
  {
    sadala: "parMani",
    image: "/images/ParMani/2.jpg",
    order: 2,
    width: 800,
    height: 1200,
  },
  {
    sadala: "parMani",
    image: "/images/ParMani/1.jpg",
    order: 1,
    width: 800,
    height: 1200,
  },
];

export default parManiImages;

import { PAKAPLOJUMI_URL, UPLOAD_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const pakalpojumiApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMeistarklasesImages: builder.query({
        query: () => ({
          url: `${PAKAPLOJUMI_URL}/meistarklases`,
        }),
        keepUnusedDataFor: 5,
      }),
    getKarikaturasImages: builder.query({
      query: () => ({
        url: `${PAKAPLOJUMI_URL}/karikaturas`,
      }),
      keepUnusedDataFor: 5,
    }),
    createKarikatura: builder.mutation({
      query: () => ({
        url: `${PAKAPLOJUMI_URL}/karikaturas`,
        method: "POST",
      }),
      invalidatesTags: ["Karikaturas"],
    }),
    updateIsPublishedKarikaturaById: builder.mutation({
      query: (data) => ({
        url: `${PAKAPLOJUMI_URL}/karikaturas/${data.id}/updateIsPublished`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Karikaturas"],
    }),
    deleteKarikaturaById: builder.mutation({
      query: (karikaturaId) => ({
        url: `${PAKAPLOJUMI_URL}/karikaturas/${karikaturaId}`,
        method: "DELETE",
      }),
    }),
    getKarikaturaById: builder.query({
      query: (karikaturaId) => ({
        url: `${PAKAPLOJUMI_URL}/karikaturas/${karikaturaId}`,
      }),
      invalidatesTags: ["Karikaturas"],
      keepUnusedDataFor: 5,
    }),
    updateKarikatura: builder.mutation({
      query: (data) => ({
        url: `${PAKAPLOJUMI_URL}/karikaturas/${data.karikaturasId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Karikaturas"],
    }),
    uploadKarikaturaImage: builder.mutation({
      query: (data) => ({
        url: `${UPLOAD_URL}`,
        method: "POST",
        body: data,
      }),
    }),

    getSienasGleznojumiImages: builder.query({
      query: () => ({
        url: `${PAKAPLOJUMI_URL}/sienuGleznojumi`,
      }),
      keepUnusedDataFor: 5,
    }),
    createSienasGleznojumi: builder.mutation({
      query: () => ({
        url: `${PAKAPLOJUMI_URL}/sienuGleznojumi`,
        method: "POST",
      }),
      invalidatesTags: ["SienuGleznojumi"],
    }),
    UpdateIsPublishedSienasGleznojumiById: builder.mutation({
      query: (data) => ({
        url: `${PAKAPLOJUMI_URL}/sienuGleznojumi/${data.id}/updateIsPublished`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["SienuGleznojumi"],
    }),
    deleteSienasGleznojumiById: builder.mutation({
      query: (sienasGleznojumsId) => ({
        url: `${PAKAPLOJUMI_URL}/sienuGleznojumi/${sienasGleznojumsId}`,
        method: "DELETE",
      }),
    }),
    updateSienasGleznojumi: builder.mutation({
      query: (data) => ({
        url: `${PAKAPLOJUMI_URL}/sienuGleznojumi/${data.sienasGleznojumsId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["SienuGleznojumi"],
    }),
    getSienasGleznojumiById: builder.query({
      query: (sienasGleznojumsId) => ({
        url: `${PAKAPLOJUMI_URL}/sienuGleznojumi/${sienasGleznojumsId}`,
      }),
      invalidatesTags: ["SienuGleznojumi"],
      keepUnusedDataFor: 5,
    }),
    uploadSienasGleznojumiImage: builder.mutation({
      query: (data) => ({
        url: `${UPLOAD_URL}`,
        method: "POST",
        body: data,
      }),
    }),

    getGleznasImages: builder.query({
      query: () => ({
        url: `${PAKAPLOJUMI_URL}/gleznas`,
      }),
      keepUnusedDataFor: 5,
    }),
    getGleznasImagesById: builder.query({
      query: (gleznasId) => ({
        url: `${PAKAPLOJUMI_URL}/gleznas/${gleznasId}`,
      }),
      keepUnusedDataFor: 5,
    }),

    getGleznasPecNumuriemImages: builder.query({
      query: () => ({
        url: `${PAKAPLOJUMI_URL}/gleznasPecNumuriem`,
      }),
      providesTags: ["GleznasPecNumuriem"],
      keepUnusedDataFor: 5,
    }),
    createGleznaPecNr: builder.mutation({
      query: () => ({
        url: `${PAKAPLOJUMI_URL}/gleznasPecNumuriem`,
        method: "POST",
      }),
      invalidatesTags: ["GleznasPecNumuriem"],
    }),
    updateGleznaPecNr: builder.mutation({
      query: (data) => ({
        url: `${PAKAPLOJUMI_URL}/gleznasPecNumuriem/${data.gleznasId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["GleznasPecNumuriem"],
    }),
    getGleznaPecNrById: builder.query({
      query: (gleznaId) => ({
        url: `${PAKAPLOJUMI_URL}/gleznasPecNumuriem/${gleznaId}`,
      }),
      invalidatesTags: ["GleznasPecNumuriem"],
      keepUnusedDataFor: 5,
    }),
    uploadGleznaPecNrImage: builder.mutation({
      query: (data) => ({
        url: `${UPLOAD_URL}`,
        method: "POST",
        body: data,
      }),
    }),
    deleteGleznaPecNrById: builder.mutation({
      query: (gleznaId) => ({
        url: `${PAKAPLOJUMI_URL}/gleznasPecNumuriem/${gleznaId}`,
        method: "DELETE",
      }),
    }),
    updateIsPublishedGleznaPecNrById: builder.mutation({
      query: (data) => ({
        url: `${PAKAPLOJUMI_URL}/gleznasPecNumuriem/${data.id}/updateIsPublished`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["GleznasPecNumuriem"],
    }),
  }),
});

export const {
  useGetMeistarklasesImagesQuery,

  useGetKarikaturasImagesQuery,
  useCreateKarikaturaMutation,
  useUpdateIsPublishedKarikaturaByIdMutation,
  useDeleteKarikaturaByIdMutation,
  useUploadKarikaturaImageMutation,
  useUpdateKarikaturaMutation,
  useGetKarikaturaByIdQuery,

  useGetGleznasImagesQuery,
  useGetGleznasImagesByIdQuery,

  useGetSienasGleznojumiImagesQuery,
  useCreateSienasGleznojumiMutation,
  useUpdateIsPublishedSienasGleznojumiByIdMutation,
  useDeleteSienasGleznojumiByIdMutation,
  useUpdateSienasGleznojumiMutation,
  useGetSienasGleznojumiByIdQuery,
  useUploadSienasGleznojumiImageMutation,

  useGetGleznasPecNumuriemImagesQuery,
  useCreateGleznaPecNrMutation,
  useUpdateGleznaPecNrMutation,
  useGetGleznaPecNrByIdQuery,
  useUploadGleznaPecNrImageMutation,
  useDeleteGleznaPecNrByIdMutation,
  useUpdateIsPublishedGleznaPecNrByIdMutation,
} = pakalpojumiApiSlice;

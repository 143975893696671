import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Loader from "../components/Loader.tsx";
import Message from "../components/Message.tsx";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useGetMeistarklasesImagesQuery } from "../slices/pakalpojumiApiSlice.js";
import meistarklasesImages from "../data/meistarklasesImages.js";

const MeistarklasesScreen = () => {
  const {
    data: meistarklasesBildes,
    isLoading,
    error,
  } = useGetMeistarklasesImagesQuery({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Container style={{ padding: "50px 0 50px 0" }}>
            <h1 className="px-3 display-4">Meistarklases</h1>
            <p
              className="px-3 mb-4"
              style={{ textAlign: "justify", fontSize: "1.5em" }}
            >
              <b>
                Nākamā pieejamā meistarklase 16. martā. Piesakies sadaļā
                kontakti, aizpildot saziņas formu.
              </b>
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              Piedāvāju zīdaiņu drēbīšu apgleznošanas meistarklases. Piedāvāju 2
              veidu modeļus:
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              <b>1. Baby shower ballītes veidā.</b> Pavadi sava mazulīša
              gaidīšanas ballīti radoši - kopā ar draudzenēm apgleznojot Tava
              gaidāmā bērniņa bodijus. Tie kalpos par ļoti mīļām un īpašām
              pirmajām drēbītēm, ko varēsi vilkt savam mazulītim. Drēbītes
              pieejamas vairākos izmēros.
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              <b>2. Klasiskas meistarklases veidā.</b> Kopā sanāk vairākas
              nepazīstamas māmiņas, grūtnieces vai interesentes. Iepriekš katrai
              tiek pieteikts vēlamais bodija izmērs. Katra apglezno drēbīti
              savam bērniņam, kura kalpos par ļoti mīļu un īpašu, māmiņas radītu
              drēbīti.
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              <b>Meistarklases ilgums</b> - 3h
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              <b>Cena no personas</b> - 40 eiro. Cenā iekļauts: kokvilnas
              bodiji, visi materiāli, telpu īre, dzērieni.
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              <b>Minimālais cilvēku skaits</b> - 6 personas
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              <b>Lokācija</b> - Rīga, bet izskatu arī izbraukuma meistarklases
              jūsu lokācijā!
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              Nāc viena vai kopā ar savām drauzenēm un radīsim mīļas lietas mūsu
              mazuļiem!
            </p>

            <Row className="g-3 px-3 my-3">
              <Gallery>
                {meistarklasesImages.map((item) => (
                  <Col key={item._id} sm={12} md={6} lg={3} xl={2}>
                    <Item
                      cropped
                      original={item.image}
                      thumbnail={item.image}
                      width={item.width}
                      height={item.height}
                      alt={item.description}
                    >
                      {({ ref, open }) => (
                        <img
                          style={{
                            objectFit: "contain",
                            cursor: "pointer",
                            width: "100%",
                            height: "100%",
                            margin: "0 0 5px 0",
                          }}
                          loading="lazy"
                          src={item.image}
                          ref={ref as React.MutableRefObject<HTMLImageElement>}
                          onClick={open}
                          alt={item.description}
                        />
                      )}
                    </Item>
                  </Col>
                ))}
              </Gallery>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default MeistarklasesScreen;

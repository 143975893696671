import React, { useEffect } from "react";
import { Row, Col, Modal, Button, Container } from "react-bootstrap";
import Loader from "../components/Loader.tsx";
import Message from "../components/Message.tsx";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useGetSienasGleznojumiImagesQuery } from "../slices/pakalpojumiApiSlice.js";

const SienuGleznojumiScreen = () => {
  const {
    data: sienuGleznojumi,
    isLoading,
    error,
  } = useGetSienasGleznojumiImagesQuery({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Container style={{ padding: "50px 0 50px 0" }}>
            <h1 className="px-3 display-4">Sienu gleznojumi</h1>
            <p className="px-3" style={{ textAlign: "justify" }}>
              Piedāvāju arī <b>apgleznot Jūsu sienas.</b> Ja Tevi šis interesē,
              tad raksti man, pastāsti savu vēlmi un domāsim. Katrs gadījums ir
              ļoti atšķirīgs un individuāls. Sākumā uzklausīšu Tavu ideju un tad
              gatavošu vairākas melnbaltas un krāsainas skices, līdz kopīgi
              nonāksim pie gala varianta, kuru pārnesīšu uz Tavas sienas!
            </p>
            <p className="px-3" style={{ textAlign: "justify" }}>
              <b>Cenas</b> atkarīgas no sienas izmēriem un sarežģītības līmeņa.
            </p>

            <Row className="g-2 px-3 my-3">
              <p style={{ textAlign: "justify" }}>
                Sienas gleznojums "HOKEJISTS" Mārupē. Hokejista aptuvenie
                izmēri: 1,2m platumā un ap 2m augstumā.
              </p>
              <Gallery>
                {sienuGleznojumi
                  .filter((item) => item.order >= 18 && item.order <= 21)
                  .map((item) => (
                    <Col key={item._id} sm={12} md={6} lg={3} xl={2}>
                      <Item
                        original={item.image}
                        thumbnail={item.image}
                        width="800"
                        height="1200"
                        alt={item.description}
                      >
                        {({ ref, open }) => (
                          <img
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              margin: "0 0 10px 0",
                            }}
                            src={item.image}
                            ref={
                              ref as React.MutableRefObject<HTMLImageElement>
                            }
                            onClick={open}
                            alt={item.description}
                          />
                        )}
                      </Item>
                    </Col>
                  ))}
              </Gallery>
            </Row>

            <Row className="g-2 px-3 my-3">
              <p style={{ textAlign: "justify" }}>
                Sienas gleznojums "BMX" Mārupē. BMX aptuvenie izmēri: 1,2m
                platumā un ap 2m augstumā.
              </p>
              <Gallery>
                {sienuGleznojumi
                  .filter((item) => item.order >= 14 && item.order <= 17)
                  .map((item) => (
                    <Col key={item._id} sm={12} md={6} lg={3} xl={2}>
                      <Item
                        original={item.image}
                        thumbnail={item.image}
                        width="800"
                        height="1200"
                        alt={item.description}
                      >
                        {({ ref, open }) => (
                          <img
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              margin: "0 0 10px 0",
                            }}
                            src={item.image}
                            ref={
                              ref as React.MutableRefObject<HTMLImageElement>
                            }
                            onClick={open}
                            alt={item.description}
                          />
                        )}
                      </Item>
                    </Col>
                  ))}
              </Gallery>
            </Row>

            <Row className="g-2 px-3 my-3">
              <p style={{ textAlign: "justify" }}>
                Sienas gleznojums Baltezerā. Aptuvenie izmēri: 10,4m platumā un
                ap 4m augstumā.
              </p>
              <Gallery>
                {sienuGleznojumi
                  .filter((item) => item.order >= 1 && item.order <= 13)
                  .map((item) => (
                    <Col key={item._id} sm={12} md={6} lg={3} xl={2}>
                      <Item
                        original={item.image}
                        thumbnail={item.image}
                        width="900"
                        height="1200"
                        alt={item.description}
                      >
                        {({ ref, open }) => (
                          <img
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              margin: "0 0 10px 0",
                            }}
                            src={item.image}
                            ref={
                              ref as React.MutableRefObject<HTMLImageElement>
                            }
                            onClick={open}
                            alt={item.description}
                          />
                        )}
                      </Item>
                    </Col>
                  ))}
              </Gallery>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default SienuGleznojumiScreen;

import React, { useState, useEffect } from "react";
import { Row, Col, Image, Nav, Container } from "react-bootstrap";
import { useGetProductsQuery } from "../slices/productsApiSlice.js";
import { LinkContainer } from "react-router-bootstrap";
import { Gallery, Item } from "react-photoswipe-gallery";
import Loader from "../components/Loader.tsx";
import Message from "../components/Message.tsx";
import "photoswipe/dist/photoswipe.css";
import parManiImages from "../data/parManiImages.js";

const ParManiScreen = () => {
  const { data: products, isLoading, error } = useGetProductsQuery({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Container style={{ padding: "50px 0 50px 0" }}>
            <h1 style={{ padding: "0px 0 20px 0" }} className="px-3 display-4">
              Par mani
            </h1>

            <Row className="g-4 mb-5">
              <Col xs={12} md={4}>
                <img
                  width="100%"
                  height="100%"
                  src="images/evija_about.jpg" // Replace with your image source
                  alt="Description of the image"
                  className="px-3 img-fluid"
                />
              </Col>
              <Col xs={12} md={8}>
                <p
                  className="px-3"
                  style={{ fontSize: "20px", textAlign: "justify" }}
                >
                  Mani sauc <b>Evija Keiša</b> un savā radošajā laikā es
                  darbojos zem zīmola <b>Geisha Art</b>.
                </p>
                <p
                  className="px-3"
                  style={{ fontSize: "20px", textAlign: "justify" }}
                >
                  Ar mākslu nodarbojos jau vairāk kā 10 gadus, kuru laikā esmu
                  izkopusi dažādus mākslus veidus. Galvenokārt, piedāvāju:{" "}
                  <b style={{ display: "inline", textDecoration: "underline" }}>
                    <LinkContainer
                      to="/pakalpojumi/gleznPecNum"
                      as={Nav.Link}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Gleznas pēc numuriem
                      </span>
                    </LinkContainer>
                  </b>
                  ,{" "}
                  <b style={{ display: "inline", textDecoration: "underline" }}>
                    <LinkContainer
                      to="/pakalpojumi/karikaturas"
                      as={Nav.Link}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Karikatūras
                      </span>
                    </LinkContainer>
                  </b>
                  ,{" "}
                  <b style={{ display: "inline", textDecoration: "underline" }}>
                    <LinkContainer
                      to="/pakalpojumi/sienuGleznojumi"
                      as={Nav.Link}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Sienu gleznojumus
                      </span>
                    </LinkContainer>
                  </b>
                  ,{" "}
                  <b style={{ display: "inline", textDecoration: "underline" }}>
                    <LinkContainer
                      to="/pakalpojumi/gleznas"
                      as={Nav.Link}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Gleznas
                      </span>
                    </LinkContainer>
                  </b>
                  ,{" "}
                  <b style={{ display: "inline", textDecoration: "underline" }}>
                    <LinkContainer
                      to="/pakalpojumi/meistarklases"
                      as={Nav.Link}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Meistarklases
                      </span>
                    </LinkContainer>
                  </b>
                  , bet vienmēr esmu atvērta arī citiem radošiem izaicinājumiem!
                </p>
                <p
                  className="px-3"
                  style={{ fontSize: "20px", textAlign: "justify" }}
                >
                  Iepazīsties ar maniem darbiem Pakalpojumi sadaļā un droši
                  sazinies, ja esi ieinteresēts! Varbūt kāds no maniem darbiem
                  var atsvaidzināt tieši Tavu mājokli vai kalpot kā lieliska
                  dāvana sev un citiem!
                </p>
                <p
                  className="px-3"
                  style={{
                    fontSize: "20px",
                    textAlign: "justify",
                    margin: "80px 0 15px 0",
                  }}
                >
                  Preses relīzes:
                </p>
                <p
                  className="px-3"
                  style={{ fontSize: "20px", textAlign: "justify" }}
                >
                  - Laikraksts "Auseklis", 12. jūlijs, 2023
                </p>

                <p
                  className="px-3"
                  style={{ fontSize: "20px", textAlign: "justify" }}
                >
                  - Raidījums "Slavenības bez filtra", Februāris, 2025
                </p>
              </Col>
            </Row>

            <Row className="g-2 px-3 my-3">
              <Gallery>
                {parManiImages.map((item) => (
                  <Col key={item._id} sm={12} md={6} lg={3} xl={2}>
                    <Item
                      original={item.image}
                      thumbnail={item.image}
                      width="800"
                      height="1200"
                      alt={item.description}
                    >
                      {({ ref, open }) => (
                        <img
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height: "100%",
                            margin: "0 0 10px 0",
                          }}
                          src={item.image}
                          ref={ref as React.MutableRefObject<HTMLImageElement>}
                          onClick={open}
                          alt={item.description}
                        />
                      )}
                    </Item>
                  </Col>
                ))}
              </Gallery>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ParManiScreen;
